import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import { IconButton } from "@material-ui/core"
import * as ROUTES from "../../constants/routes"

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginRight: "auto",
    padding: 0,
  },
}))

const query = graphql`
  query getLogo {
    file(relativePath: { eq: "Logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Logo = ({ width }) => {
  const classes = useStyles()
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <IconButton classes={{ root: classes.iconButton }}>
      <Link to={ROUTES.HOME}>
        <Image fluid={fluid} style={{ width }} />
      </Link>
    </IconButton>
  )
}

Logo.defaultProps = {
  width: "150px",
}

export default Logo
