import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: "4rem",
    fontWeight: "700",
    textAlign: "center",
    "&::after": {
      display: "block",
      content: "' '",
      background: theme.palette.primary.main,
      width: "10rem",
      height: "2px",
      margin: "0.5rem auto 0.5rem auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8rem",
    },
  },
}))

const SectionTitle = ({ title }) => {
  const classes = useStyles()
  return (
    <Typography
      variant="h3"
      component="h3"
      classes={{
        root: classes.heading,
      }}
    >
      {title}
    </Typography>
  )
}

export default SectionTitle
