import React from "react"
import HeroBackground from "./HeroBackground"
import HeroTitle from "../Title/HeroTitle"

const PageHero = ({ imageSrc, title }) => {
  return (
    <HeroBackground imageSrc={imageSrc} height="40vh">
      <HeroTitle text={title} />
    </HeroBackground>
  )
}

export default PageHero
