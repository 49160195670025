import React from "react"
import { Link } from "react-scroll"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Button } from "@material-ui/core"
import { FaHeadphones, FaEnvelope } from "react-icons/fa"
import HeroBackground from "./HeroBackground"
import HeroTitle from "../Title/HeroTitle"

const useStyles = makeStyles(theme => ({
  subHeading: {
    fontFamily: "Papyrus",
    textTransform: "uppercase",
    textAlign: "center",
    opacity: "0.6",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
  },
  button: {
    fontSize: "0.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  greenButton: {
    borderColor: theme.palette.primary.main,
    marginRight: "3rem",
    transition: "all 0.3s",
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
  },
  yellowButton: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    transition: "all 0.3s",
    "&:hover": {
      background: theme.palette.warning.main,
      color: theme.palette.background.default,
    },
  },
}))

const query = graphql`
  query getHomeHero {
    hero: contentfulHeroImages {
      home {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

const StartHero = () => {
  const classes = useStyles()
  const {
    hero: {
      home: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <HeroBackground imageSrc={fluid} height="80vh">
      <HeroTitle text="Cool & Deadly" />
      <Typography
        variant="h3"
        component="h3"
        classes={{ root: classes.subHeading }}
      >
        Reggae on Air since 1995
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          classes={{ root: `${classes.button} ${classes.greenButton}` }}
          variant="outlined"
          color="primary"
          startIcon={<FaHeadphones />}
        >
          <Link
            to="episodes"
            spy={true}
            hashSpy={true}
            smooth={"easeInOutExpo"}
            offset={-50}
            duration={1000}
          >
            Sendungen
          </Link>
        </Button>
        <Button
          classes={{
            root: `${classes.button} ${classes.yellowButton}`,
          }}
          variant="outlined"
          startIcon={<FaEnvelope />}
        >
          <Link
            to="contact"
            spy={true}
            hashSpy={true}
            smooth={"easeInOutExpo"}
            offset={-50}
            duration={1000}
          >
            Kontakt
          </Link>
        </Button>
      </div>
    </HeroBackground>
  )
}

export default StartHero
