import React from "react"
import { MenuList, MenuItem } from "@material-ui/core"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { MENULINKS } from "../../constants/menuLinks"

const useStyles = makeStyles(theme => ({
  menuList: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  menuItem: {
    textTransform: "capitalize",
    padding: "0.5rem 0",
    width: "fit-content",
    opacity: "0.4",
    transition: "all 0.3s",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      marginRight: "1rem",
    },
    "&:hover": {
      opacity: "1",
      transform: "translateY(-3px)",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  },
}))

const FooterNav = () => {
  const classes = useStyles()

  return (
    <MenuList
      classes={{
        root: classes.menuList,
      }}
      component="ul"
    >
      {MENULINKS.map(link => (
        <MenuItem
          classes={{
            root: classes.menuItem,
          }}
          key={link.id}
        >
          <Link to={link.route}>{link.title}</Link>
        </MenuItem>
      ))}
    </MenuList>
  )
}

export default FooterNav
