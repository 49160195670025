import React from "react"
import { MenuList, MenuItem, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as LINKS from "../../constants/links"
import { FaFacebookSquare, FaGlobe, FaRecordVinyl } from "react-icons/fa"
import { GrYoutube } from "react-icons/gr"

const socialLinks = [
  { title: "facebook", link: LINKS.FACEBOOK, icon: <FaFacebookSquare /> },
  { title: "youtube", link: LINKS.YOUTUBE, icon: <GrYoutube /> },
  { title: "nrwision", link: LINKS.NRWISION, icon: <FaGlobe /> },
  { title: "podomatic", link: LINKS.PODOMATIC, icon: <FaRecordVinyl /> },
]

const useStyles = makeStyles(theme => ({
  menuList: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    opacity: "0.6",
    transition: "all 0.3s",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))

const SocialLinks = () => {
  const classes = useStyles()

  return (
    <MenuList classes={{ root: classes.menuList }}>
      {socialLinks.map(link => (
        <MenuItem key={link.title}>
          <Tooltip title={link.title.toUpperCase()} enterDelay={300}>
            <a
              className={classes.link}
              href={link.link}
              target="_blank"
              rel="noreferrer"
            >
              {link.icon}
            </a>
          </Tooltip>
        </MenuItem>
      ))}
    </MenuList>
  )
}

export default SocialLinks
