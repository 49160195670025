import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainHeading: {
    fontFamily: "Papyrus",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "5rem",
    },
  },
}))

const HeroTitle = ({ text }) => {
  const classes = useStyles()
  return (
    <Typography
      variant="h1"
      component="h1"
      classes={{ root: classes.mainHeading }}
    >
      {text}
    </Typography>
  )
}

export default HeroTitle
