import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

let theme = createMuiTheme({
  typography: {
    fontFamily: ["Futura", "Open Sans"].join(","),
  },
  palette: {
    primary: {
      main: "#00AC65",
    },
    secondary: {
      main: "#1C1C1C",
    },
    error: {
      main: "#F04A41",
    },
    warning: {
      main: "#F4C439",
    },
    text: {
      primary: "#ECF0F1",
      secondary: "#FFF",
    },
    background: {
      default: "#131313",
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
