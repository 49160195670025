import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Typography, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FaHeart } from "react-icons/fa"
import Logo from "../Logo/"
import * as ROUTES from "../../constants/routes"
import SocialLinks from "../SocialLinks/"

const useStyles = makeStyles(theme => ({
  legalButton: {
    opacity: "0.4",
    transition: "all 0.3s",
    "&:hover": {
      background: "none",
      opacity: "1",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "1rem",
    },
  },
  buttonLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "all 0.3s",
  },
  heartIcon: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    margin: "0 0.4rem",
  },
  smallText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0.8",
    marginTop: "1rem",
  },
}))

const Legal = () => {
  const classes = useStyles()

  return (
    <Fragment>
      <Logo width={"220px"} />
      <SocialLinks />
      <Typography
        variant="body2"
        component="small"
        classes={{ root: classes.smallText }}
      >
        Made with <FaHeart className={classes.heartIcon} /> in Münster, Germany
      </Typography>
      <div>
        <Button classes={{ root: classes.legalButton }}>
          <Link to={ROUTES.IMPRESSUM} className={classes.buttonLink}>
            Impressum
          </Link>
        </Button>
        <Button classes={{ root: classes.legalButton }}>
          <Link to={ROUTES.DATENSCHUTZ} className={classes.buttonLink}>
            Datenschutz
          </Link>
        </Button>
        <Button classes={{ root: classes.legalButton }}>
          <Link
            to={ROUTES.BILDQUELLENVERZEICHNIS}
            className={classes.buttonLink}
          >
            Bildquellen
          </Link>
        </Button>
      </div>
    </Fragment>
  )
}

export default Legal
