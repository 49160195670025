import React, { Fragment } from "react"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import BackgroundImage from "gatsby-background-image"
import ReggaeDivider from "../ReggaeDivider/"

const useStyles = makeStyles(theme => ({
  backgroundImage: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  backgroundOverlay: {
    backgroundColor: "rgba(0,0,0,0.6)",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "0 1rem",
    },
  },
  container: {
    padding: "3rem 0",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: "1",
  },
}))

const HeroBackground = ({ children, imageSrc, height }) => {
  const classes = useStyles()
  return (
    <Fragment>
      <BackgroundImage
        fluid={imageSrc}
        className={classes.backgroundImage}
        style={{ height }}
      >
        <div className={classes.backgroundOverlay}>
          <div className={classes.content}>
            <Container maxWidth="lg" classes={{ root: classes.container }}>
              {children}
            </Container>
          </div>
        </div>
      </BackgroundImage>
      <ReggaeDivider />
    </Fragment>
  )
}

export default HeroBackground
