import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  divider: {
    display: "block",
    width: "100%",
    height: ".25rem",
  },
  green: {
    background: theme.palette.primary.main,
  },
  yellow: {
    background: theme.palette.warning.main,
  },
  red: {
    background: theme.palette.error.main,
  },
}))

const ReggaeDivider = () => {
  const classes = useStyles()

  return (
    <Fragment>
      <div className={`${classes.green} ${classes.divider}`}>&nbsp;</div>
      <div className={`${classes.yellow} ${classes.divider}`}>&nbsp;</div>
      <div className={`${classes.red} ${classes.divider}`}>&nbsp;</div>
    </Fragment>
  )
}

export default ReggaeDivider
