import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as LINKS from "../../constants/links"

const useStyles = makeStyles(theme => ({
  subFooter: {
    padding: "1rem 0",
    textAlign: "center",
    background: theme.palette.background.default,
  },
  text: {
    display: "block",
    opacity: "0.4",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    transition: "all 0.3s",
  },
}))

const SubFooter = () => {
  const classes = useStyles()

  return (
    <div className={classes.subFooter}>
      <Typography
        variant="body2"
        component="small"
        classes={{ root: classes.text }}
      >
        Copyright &copy; {new Date().getFullYear()} Cool & Deadly
      </Typography>
    </div>
  )
}

export default SubFooter
