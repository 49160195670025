import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import theme from "../../theme/"
import { ThemeProvider } from "@material-ui/styles"
import { CssBaseline, Box } from "@material-ui/core"
import PropTypes from "prop-types"
import Navbar from "../Navbar/"
import Footer from "../Footer/"
import TopHeader from "../TopHeader/"
import StartHero from "../Hero/StartHero"
import PageHero from "../Hero/PageHero"
import * as ROUTES from "../../constants/routes"

const useStyles = makeStyles({
  mainWrapper: {
    overflowX: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 87px - 24px)",
  },
  box: {
    flexGrow: 1,
  },
})

const MainLayout = ({ children, location, imageSrc, title }) => {
  const classes = useStyles()
  const isHomePage = location.pathname === ROUTES.HOME

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainWrapper}>
        <CssBaseline />
        {isHomePage && <TopHeader />}
        <Navbar location={location} />
        {isHomePage ? (
          <StartHero />
        ) : (
          <PageHero imageSrc={imageSrc} title={title} />
        )}
        <div classes={{ root: classes.box }}>
          <Box component="main" classes={{ root: classes.box }}>
            {children}
          </Box>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
