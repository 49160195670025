import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Box, Grid } from "@material-ui/core"
import ReggaeDivider from "../ReggaeDivider/"
import Legal from "./Legal"
import FooterNav from "./FooterNav"
import SubFooter from "./SubFooter"

const useStyles = makeStyles(theme => ({
  footer: {
    background: theme.palette.secondary.main,
  },
  box: {
    textAlign: "center",
    padding: "2rem 0",
  },
  gridContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <ReggaeDivider />
      <Container maxWidth="lg" classes={{ root: classes.container }}>
        <Box component="div" classes={{ root: classes.box }}>
          <Grid container classes={{ container: classes.gridContainer }}>
            <Grid item sm={12} md={4}>
              <FooterNav />
            </Grid>
            <Grid item sm={12} md={4}>
              <Legal />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <SubFooter />
    </footer>
  )
}

export default Footer
