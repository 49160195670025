import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  Drawer,
  IconButton,
} from "@material-ui/core"
import { MdMenu } from "react-icons/md"
import CloseIcon from "@material-ui/icons/Close"
import Logo from "../Logo/"
import Navmenu from "../Navmenu/"

const drawerWidth = "100%"
const useStyles = makeStyles(theme => ({
  navbar: {
    margin: 0,
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: "flex",
    padding: "0.5rem 0",
  },
  iconButton: {
    padding: "0",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.default,
    zIndex: -1,
  },
  drawerContainer: {
    overflow: "auto",
    padding: 16,
    height: "100%",
  },
  NavIcon: {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  closeIcon: {
    color: theme.palette.text.primary,
  },
}))

const Navbar = ({ location }) => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const theme = useTheme()
  const downXS = useMediaQuery(theme.breakpoints.down("xs"))
  const upSM = useMediaQuery(theme.breakpoints.up("sm"))

  const handleOpenDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
  }

  return (
    <div className={classes.navbar}>
      <AppBar position="static" classes={{ root: classes.appbar }}>
        <Toolbar>
          <Logo width={upSM ? "9rem" : "7rem"} />
          <div>
            {downXS ? (
              <IconButton
                onClick={handleOpenDrawer}
                classes={{ root: classes.iconButton }}
              >
                <MdMenu className={classes.NavIcon} />
              </IconButton>
            ) : (
              <Navmenu location={location} />
            )}
          </div>
        </Toolbar>
      </AppBar>
      {downXS && (
        <Drawer
          anchor="top"
          BackdropProps={{ invisible: true }}
          className={classes.drawer}
          variant="temporary"
          open={drawerOpen}
          onClose={handleOpenDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.closeContainer}>
              <IconButton
                classes={{ label: classes.closeIcon }}
                onClick={handleCloseDrawer}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Navmenu location={location} />
          </div>
        </Drawer>
      )}
    </div>
  )
}

export default Navbar
