import React from "react"
import { SvgIcon, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { IoIosRadio } from "react-icons/io"

const useStyles = makeStyles(theme => ({
  topheader: {
    margin: 0,
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem 1rem",
  },
  text: {
    fontSize: "0.8rem",
    textAlign: "center",
    padding: "0 1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  bold: {
    fontWeight: 700,
  },
}))

const TopHeader = () => {
  const classes = useStyles()

  return (
    <div className={classes.topheader}>
      <SvgIcon>
        <IoIosRadio />
      </SvgIcon>
      <Typography variant="body1" component="p" className={classes.text}>
        Jeden <span className={classes.bold}>4. Samstag </span>um{" "}
        <span className={classes.bold}>20 Uhr </span>auf{" "}
        <span className={classes.bold}>Antenne Münster 95.4</span>
      </Typography>
      <SvgIcon>
        <IoIosRadio />
      </SvgIcon>
    </div>
  )
}

export default TopHeader
