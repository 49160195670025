import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, useTheme } from "@material-ui/core"
import SectionTitle from "../Title/SectionTitle"

const useStyles = makeStyles(theme => ({
  section: {
    padding: "6rem 0",
    [theme.breakpoints.up("sm")]: {
      padding: "10rem 0",
    },
  },
}))

const Section = ({ children, secondBG, title }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <section
      className={classes.section}
      style={{ background: `${secondBG && theme.palette.secondary.main}` }}
    >
      <Container maxWidth="lg">
        {title && <SectionTitle title={title} />}
        {children}
      </Container>
    </section>
  )
}
export default Section
