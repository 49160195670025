import React from "react"
import { MenuList, MenuItem, useTheme, useMediaQuery } from "@material-ui/core"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { MENULINKS } from "../../constants/menuLinks"

const useStyles = makeStyles(theme => ({
  menuList: {
    display: "flex",
  },
  menuListMobile: {
    flexDirection: "column",
  },
  menuItem: {
    textTransform: "uppercase",
    opacity: "0.4",
    transition: "all 0.3s",
    "&:hover": {
      opacity: "1",
      transform: "translateY(-3px)",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
  },
  selectedMenuItem: {
    opacity: "1",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

const Navmenu = ({ location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <MenuList
      classes={{
        root: `${isMobile ? classes.menuListMobile : classes.menuList}`,
      }}
      component="ul"
    >
      {MENULINKS.map(link => (
        <Link to={link.route} className={classes.link} key={link.id}>
          <MenuItem
            disableRipple
            selected={location.pathname === link.route}
            classes={{
              root: classes.menuItem,
              selected: classes.selectedMenuItem,
            }}
          >
            {link.title}
          </MenuItem>
        </Link>
      ))}
    </MenuList>
  )
}

export default Navmenu
